export const englishContent = {
    foodMenu: 'Food Menu',
    drinkMenu: 'Drink Menu',
    reviews: 'Reviews',
    contact: 'Contact',
}

export const spanishContent = {
    foodMenu: 'Menú de Comida',
    drinkMenu: 'Menú de Bebidas',
    reviews: 'Reseñas',
    contact: 'Contacto',
}
