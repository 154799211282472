// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.drink-section {
    /* background-color: #ffffff; */
    /* background-color: rgb(255, 238, 206); */
    border: 2px solid black;
    margin: 10px;
}

.drink-warning {
    display: flex;
    justify-content: center;
    text-align: center;
    /* font-style: italic; */
}`, "",{"version":3,"sources":["webpack://./src/components/DrinksMenu/DrinksMenu.css"],"names":[],"mappings":"AAAA;IACI,+BAA+B;IAC/B,0CAA0C;IAC1C,uBAAuB;IACvB,YAAY;AAChB;;AAEA;IACI,aAAa;IACb,uBAAuB;IACvB,kBAAkB;IAClB,wBAAwB;AAC5B","sourcesContent":[".drink-section {\n    /* background-color: #ffffff; */\n    /* background-color: rgb(255, 238, 206); */\n    border: 2px solid black;\n    margin: 10px;\n}\n\n.drink-warning {\n    display: flex;\n    justify-content: center;\n    text-align: center;\n    /* font-style: italic; */\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
