export const englishContent = {
    appetizers: 'Appetizers',
    salads: 'Salads',
    entrees: 'Entrees',
    desserts: 'Desserts',
}

export const spanishContent = {
    appetizers: 'Aperitivos',
    salads: 'Ensaladas',
    entrees: 'Platos Fuertes',
    desserts: 'Postres',
}
