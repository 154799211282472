// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.first-three-menu-nav-buttons {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    width: 100%;
    margin-bottom: 5px;
}

.second-three-menu-nav-buttons {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    width: 100%;
}

.drink-nav-options {
    flex-direction: column;
}

.drink-nav-button {
    width: 150px;
}
`, "",{"version":3,"sources":["webpack://./src/components/DrinksMenuNav/DrinksMenuNav.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,mBAAmB;IACnB,6BAA6B;IAC7B,WAAW;IACX,kBAAkB;AACtB;;AAEA;IACI,aAAa;IACb,mBAAmB;IACnB,6BAA6B;IAC7B,WAAW;AACf;;AAEA;IACI,sBAAsB;AAC1B;;AAEA;IACI,YAAY;AAChB","sourcesContent":[".first-three-menu-nav-buttons {\n    display: flex;\n    flex-direction: row;\n    justify-content: space-around;\n    width: 100%;\n    margin-bottom: 5px;\n}\n\n.second-three-menu-nav-buttons {\n    display: flex;\n    flex-direction: row;\n    justify-content: space-around;\n    width: 100%;\n}\n\n.drink-nav-options {\n    flex-direction: column;\n}\n\n.drink-nav-button {\n    width: 150px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
