// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.body-fade-in {
    opacity: 0;
    transform: translateY(25px);
    transition: opacity 1s ease-out, transform 1s ease-out;
}

.body-fade-in.show {
    opacity: 1;
    transform: translateY(0px);
}

.footer-fade-in {
    opacity: 0;
    transform: translateY(0px);
    transition: opacity 1s ease-out, transform 1s ease-out;
}

.footer-fade-in.show {
    opacity: 1;
    transform: translateY(0px);
}`, "",{"version":3,"sources":["webpack://./src/components/HomePage/HomePage.css"],"names":[],"mappings":"AAAA;IACI,UAAU;IACV,2BAA2B;IAC3B,sDAAsD;AAC1D;;AAEA;IACI,UAAU;IACV,0BAA0B;AAC9B;;AAEA;IACI,UAAU;IACV,0BAA0B;IAC1B,sDAAsD;AAC1D;;AAEA;IACI,UAAU;IACV,0BAA0B;AAC9B","sourcesContent":[".body-fade-in {\n    opacity: 0;\n    transform: translateY(25px);\n    transition: opacity 1s ease-out, transform 1s ease-out;\n}\n\n.body-fade-in.show {\n    opacity: 1;\n    transform: translateY(0px);\n}\n\n.footer-fade-in {\n    opacity: 0;\n    transform: translateY(0px);\n    transition: opacity 1s ease-out, transform 1s ease-out;\n}\n\n.footer-fade-in.show {\n    opacity: 1;\n    transform: translateY(0px);\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
